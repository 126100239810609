import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import LargeHeroImageText from '../components/microsite/LargeHeroImageText'
import TilesBox from '../components/microsite/Tiles'
import ElementSplit from '../components/microsite/ElementSplit'
import ListComponent from '../components/microsite/ListComponent'
import CallToAction from '../components/microsite/CallToAction'
import { isMobile } from 'react-device-detect'
import CallToActionMobile from '../components/microsite/CallToActionMobile'
import Contact from '../components/microsite/Contact'
import { useRef } from 'react'
import Podcast from '../components/microsite/Podcast'

// TODO: Maybe add a reroute to this and give this file an english name again for consistent naming?

const potentialsTiles = [
  { icon: 'fa-money-bill-alt', content: 'Neue Monetarisierungsmodelle.' },
  {
    icon: 'fa-lightbulb',
    content: 'Optimierung zukünftiger und bestehender Produkte.',
  },
  {
    icon: 'fa-cogs',
    content:
      'Mehrwerte im Service bei gleichen oder geringeren Kosten für die Kunden.',
  },
  {
    icon: 'fa-project-diagram',
    content: 'Prozesse auf der Organisationsebene sind automatisierbar.',
  },
  {
    icon: 'fa-brain',
    content:
      'Prozesse & Funktionen auf der Produktebene sind automatisierbar und intelligent gestaltet.',
  },
  {
    icon: 'fa-lightbulb',
    content: 'Die Innovationsführerschaft ist abgesichert.',
  },
]

const colenioTiles = [
  {
    icon: 'fa-brain',
    content:
      'Wir haben jahrelange Erfahrung mit großen Digitalisierungsvorhaben.',
  },
  {
    icon: 'fa-sync-alt',
    content: 'Wir begleiten den Entwicklungszyklus von A bis Z.',
  },
  {
    icon: 'fa-lock',
    content:
      'Wir bringen Experten im Bereich “Sicherheit & Datenschutz” mit: Security by design.',
  },
  {
    icon: 'fa-cogs',
    content: 'Wir besitzen Branchenexpertise im Maschinenbau.',
  },
  {
    icon: 'fa-chart-line',
    content:
      'Wir unterstützen bei der Validierung und Optimierung Ihrer Digitalisierungsvorhaben.',
  },
  {
    icon: 'fa-lightbulb',
    content:
      'Wir identifizieren und erabeiten wesentliche Nutzen & Mehrwerte für Ihr Unternehmen',
  },
  {
    icon: 'fa-hand-holding-usd',
    content:
      'Wir interessieren uns nicht für Spielereien - wir möchten Nutzen bringen. Schnell und messbar.',
  },
]

const challenges = [
  {
    icon: 'fa-brain',
    title: 'Erfahrung',
    content:
      'Viele reden über große Digitalisierungsvorhaben, wenige packen es wirklich an. Vielen Unternehmen mangelt es an Erfahrung.',
  },
  {
    icon: 'fa-lock',
    title: 'Security',
    content:
      'Sicherheit & Datenschutz erreichen im IoT Umfeld eine andere Ebene. Daten leben plötzlich hochgradig verteilt. Und das sogar physisch.',
  },
  {
    icon: 'fa-lightbulb',
    title: 'Innovation',
    content:
      'Die Innovationszyklen werden kürzer. Hinzu kommen unterschiedliche Innovationszyklen zwischen Hard- und Software - kein leichtes Fahrwasser.',
  },
  {
    icon: 'fa-frown-open',
    title: 'Unsicherheit',
    content:
      'Führt der Weg zum Erfolg? Kann die Organisationsstruktur die Veränderungsvorhaben tragen und sind sie wirklich zukunftsfähig?',
  },
  {
    icon: 'fa-question',
    title: 'Unklarheit',
    content:
      'Wie kann ich aus der Digitalisierung konkreten Nutzen ziehen? Oder ist das wieder nur ein Trend?',
  },
  {
    icon: 'fa-money-bill',
    title: 'Kosten vs. Nutzen',
    content:
      'Hohe Ausgaben, wenig Nutzen? Wie steht es um den Return Of Invest? Sind das sinnvolle Ausgaben?',
  },
]

const App = props => {
  const { data } = props
  const contactRef = useRef(null)
  const scrollToBottom = () => {
    contactRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Layout>
      <LargeHeroImageText
        title="Digitale Transformation im Maschinenbau"
        subtitle="Veränderung einer Branche"
        img={data.hero.childImageSharp}
      >
        Maßgeschneiderte Software-Lösungen, Sicherheit und Compliance aus einer
        Hand – das ist die Stärke der colenio GmbH & Co. KG aus dem Rheinland.
        Das Team aus Ingenieuren, Softwarearchitekten, Entwicklern und Juristen
        sorgt mit regionaler Präsenz, umfassendem Know-how und pragmatischer
        Vorgehensweise für eine fachgerechte Umsetzung anspruchsvoller
        Digitalisierungsprojekte „Made in Germany“.
      </LargeHeroImageText>

      <ElementSplit headline="Sie möchten weniger lesen und mehr hören?"></ElementSplit>

      <Podcast
        img={data.podcast.childImageSharp}
        imgLink="https://iotusecase.com/podcast/mittelstaendischer-maschinen-und-anlagenbau-das-ist-der-grundstein-fuer-predictive-maintenance/"
      >
        Zusammen mit unserem Partner Elasticsearch und IoT Use Case haben wir
        einen Podcast über Digitalisierung im mittelständischen Maschinenbau
        aufgenommen. Wir sprechen über konkrete Projekte und die ersten Schritte
        in die Richtung Predictive Maintenance.
        <br></br>
        <a href="https://iotusecase.com/podcast/mittelstaendischer-maschinen-und-anlagenbau-das-ist-der-grundstein-fuer-predictive-maintenance/">
          Hier
        </a>{' '}
        kommen Sie zum Podcast.
      </Podcast>

      <ElementSplit
        headline="Digitalisierung verändert die Wertschöpfung."
        subline="Doch wie genau? Was bringt sie mit?"
      ></ElementSplit>

      <TilesBox
        img={data.parallax.childImageSharp}
        tiles={potentialsTiles}
      ></TilesBox>

      <ElementSplit
        headline="Herausforderungen der Digitalisierung."
        subline="Es ist nicht immer einfach."
      ></ElementSplit>
      <ListComponent
        img={data.parallax2.childImageSharp}
        items={challenges}
      ></ListComponent>

      <ElementSplit
        headline="Den Weg der Digitalisierung bestreiten?"
        subline="colenio ist der Gefährte auf der digitalen Reise."
      ></ElementSplit>

      <TilesBox
        img={data.parallax3.childImageSharp}
        tiles={colenioTiles}
      ></TilesBox>
      {/* Dummy ref to enable scroll to view, cf. https://stackoverflow.com/a/41700815*/}
      <div ref={contactRef}></div>
      <ElementSplit
        headline="Sie möchten unsere Kompetenzen nutzen?"
        subline="Gerne. Vereinbaren Sie einen Termin für Ihren kostenlosen Innovations-Workshop."
      ></ElementSplit>
      <Contact
        name="Engineering & Manufacturing"
        mail="sales@colenio.com"
      ></Contact>
      {/* Maybe move CTA into Contact? */}
      {isMobile ? (
        <CallToActionMobile onClick={scrollToBottom} />
      ) : (
        <CallToAction headline="Interesse an einem kostenlosen Workshop?" />
      )}
    </Layout>
  )
}

export default App

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "plasma.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1600
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    parallax: file(relativePath: { eq: "production2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2400
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    parallax2: file(relativePath: { eq: "prodline.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2400
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
    parallax3: file(relativePath: { eq: "formentor.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 2400
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: NORTH }
        )
      }
    }
    podcast: file(relativePath: { eq: "podcast-iot-use-case.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          aspectRatio: 1.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
